import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import { equal, or } from 'macro-decorators';

import { apiBaseURL, requestsNamespace } from 'qonto/constants/hosts';
import {
  APPROVAL_WORKFLOW_STATUS,
  REQUEST_STATUS_COLORS,
  REQUEST_TYPES,
  STATUS,
} from 'qonto/constants/requests';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';

export default class RequestModel extends Model {
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service featuresManager;

  @belongsTo('membership', { async: false, inverse: null }) approver;
  @belongsTo('approval-workflow-state', { async: true, inverse: null }) approvalWorkflowState;

  @attr('string') requestType;
  @attr('string') status;
  @attr('string') approvalWorkflowStatus;
  @attr('string') declinedNote;
  @attr note;
  @attr() processedAt;

  @or(STATUS.CANCELED, STATUS.DECLINED) ghost;

  @equal('status', STATUS.CANCELED) canceled;
  @equal('status', STATUS.DECLINED) declined;
  @equal('status', STATUS.APPROVED) approved;
  @equal('status', STATUS.PENDING) pending;

  @equal('approvalWorkflowStatus', APPROVAL_WORKFLOW_STATUS.COMPLETED) completed;
  @equal('approvalWorkflowStatus', APPROVAL_WORKFLOW_STATUS.AWAITS_MY_APPROVAL) awaitsApproval;
  @attr('boolean', { defaultValue: true }) lastStep;

  get displayedStatus() {
    switch (this.status) {
      case STATUS.APPROVED:
        return this.intl.t('request.status.approved');
      case STATUS.DECLINED:
        return this.intl.t('request.status.rejected');
      case STATUS.PENDING:
        return this.intl.t('request.status.pending');
      case STATUS.CANCELED:
        return this.intl.t('request.status.canceled');
      default:
        return null;
    }
  }

  get statusDisplayInfo() {
    return {
      displayedStatus: this.displayedStatus,
      color: REQUEST_STATUS_COLORS[this.status],
    };
  }

  get requestAmount() {
    switch (this.requestType) {
      case REQUEST_TYPES.MILEAGE:
      case REQUEST_TYPES.EXPENSE_REPORT: {
        return this.amount;
      }
      case REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION: {
        return this.totalAmount;
      }
      case REQUEST_TYPES.TRANSFER:
        return { value: this.amount, currency: this.amountCurrency };
      case REQUEST_TYPES.MULTI_TRANSFER:
        return {
          value: this.totalTransfersAmount,
          currency: this.totalTransfersAmountCurrency,
        };
      case REQUEST_TYPES.VIRTUAL_CARD:
        return { value: this.paymentMonthlyLimit, currency: this.currency };
      case REQUEST_TYPES.FLASH_CARD:
        return { value: this.paymentLifespanLimit, currency: this.currency };
    }
  }

  get isCredit() {
    return this.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION;
  }

  @waitFor
  async isAwaitingMyApproval() {
    let organization = this.organizationManager.organization;
    let { requests } = await this.networkManager.request(
      `${apiBaseURL}/${requestsNamespace}/requests?organization_id=${organization.id}&approval_workflow_status=${APPROVAL_WORKFLOW_STATUS.AWAITS_MY_APPROVAL}&per_page=500`,
      {
        method: 'GET',
      }
    );
    return requests?.some(({ id }) => id === this.id);
  }

  @waitFor
  async approveRequest() {
    let { bank_account_id } = this.serialize();
    let data = { bank_account_id };

    try {
      let response = await apiAction(this, { method: 'POST', path: 'approve', data });

      if (
        this.featuresManager.isEnabled('approvalWorkflows') &&
        !(await this.isAwaitingMyApproval())
      ) {
        let transferKey = Object.keys(response)[0];
        response[transferKey].status = STATUS.APPROVED;
      }

      this.store.pushPayload(response);
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async cancelRequest() {
    try {
      let response = await apiAction(this, { method: 'POST', path: 'cancel' });
      this.store.pushPayload(response);
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async declineRequest() {
    let { declined_note } = this.serialize();
    let data = { declined_note };

    try {
      let response = await apiAction(this, { method: 'POST', path: 'decline', data });
      this.store.pushPayload(response);
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }
}
