import Model, { attr, belongsTo } from '@ember-data/model';

export default class SubscriptionModel extends Model {
  @attr('string') code;
  @attr devices;

  @belongsTo('membership', { async: true, inverse: 'subscriptions' }) membership;

  get catCode() {
    return this.code.split('_')[0];
  }

  get notCode() {
    if (!this.code.includes('_')) return this.code;

    let splitCode = this.code.split('_');
    return splitCode.slice(1, splitCode.length).join('_');
  }

  get pushDisabled() {
    return this.notCode === 'monthly_invoice_reporting';
  }
}
