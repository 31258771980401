import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import { STATUS } from 'qonto/constants/receivable-invoice';
import { NetworkManagerError } from 'qonto/services/network-manager';
import { ErrorInfo } from 'qonto/utils/error-info';

const SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
const SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
const PRIMARY_ATTRIBUTE_KEY = 'base';

export default class ReceivableInvoiceModel extends Model {
  /** @type {string} */
  @attr locale;
  /** @type {string} */
  @attr number;
  /** @type {string} */
  @attr('string') issueDate; // YYYY-MM-DD
  /** @type {string} */
  @attr('string') dueDate; // YYYY-MM-DD
  /** @type {string} YYYY-MM-DD */
  @attr paidAt;
  /** @type {boolean} */
  @attr overdue;
  /** @type {string} */
  @attr purchaseOrder;
  /** @type {string} */
  @attr bic;
  /** @type {string} */
  @attr iban;
  /** @type {string} */
  @attr beneficiaryName;
  /** @type {string} */
  @attr reference;
  /** @type {string} */
  @attr termsAndConditions;
  /** @type {'canceled'|'paid'|'unpaid'|'draft'} */
  @attr status;
  /** @type {string} */
  @attr amountDue;
  /** @type {string} */
  @attr subtotal;
  /** @type {string} */
  @attr vatAmount;
  /** @type {string} */
  @attr contactEmail;
  /** @type {string} */
  @attr stampDutyAmount;
  /** @type {null|'approved'|'not_delivered'|'pending'|'submitted'|'rejected'} */
  @attr einvoicingStatus;
  /** @type {hash} */
  @attr('hash') customerSnapshot;
  /** @type {Date} */
  @attr updatedAt;
  /** @type {Date} */
  @attr createdAt;
  /** @type {string} */
  @attr header;
  /** @type {string} */
  @attr footer;
  /** @type {string} YYYY-MM-DD */
  @attr performanceDate;
  /** @type {string} */
  @attr templateVersion;
  /** @type {hash} */
  @attr('hash') settings;
  /** @type {hash} */
  @attr('hash') organizationSnapshot;
  /** @type {string} */
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) imported;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) hasDuplicates;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) directDebitEnabled;
  /** @type {string} */
  @attr fileName;
  /** @type {string} YYYY-MM-DD */
  @attr nextReminderDate;

  /** @type {Customer} */
  @belongsTo('client-hub', {
    async: true,
    inverse: null,
  })
  customer;
  /** @type {Organization} */
  @belongsTo('organization', { async: false, inverse: null }) organization;
  /** @type {Organization} */
  @belongsTo('bankAccount', { async: true, inverse: null }) bankAccount;
  /** @type {Attachment} */
  @belongsTo('attachment', { async: true, inverse: null }) attachment;

  /** @type {Payment} */
  @belongsTo('receivable-invoice/payment', { async: false, inverse: null }) payment;
  /** @type {WelfareFund} */
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;

  /** @type {Quote} */
  @belongsTo('quote', { async: true, inverse: 'receivableInvoices' }) quote;

  /** @type {Item} */
  @hasMany('receivable-invoice/item', { async: false, inverse: 'receivableInvoice' }) items;

  /** @type {ReceivableCreditNote} */
  @hasMany('receivable-credit-note', { async: true, inverse: 'receivableInvoice' })
  receivableCreditNotes;

  /** @type {InvoiceSubscription} */
  @belongsTo('invoice-subscription', { async: true, inverse: null }) invoiceSubscription;

  /** @type {directDebitSubscription} */
  @belongsTo('direct-debit-subscription', { async: true, inverse: null }) directDebitSubscription;

  @service intl;
  @service networkManager;
  @service sentry;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;
  @tracked fileType = null;

  @waitFor
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  @waitFor
  async saveAsDraft() {
    this.status = STATUS.DRAFT;
    try {
      return await super.save(...arguments);
    } catch (error) {
      this.status = STATUS.UNPAID;
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  @waitFor
  async updateDraft() {
    try {
      let payload = this.serialize({ includeId: true });
      let response = await apiAction(this, {
        method: 'PUT',
        data: payload,
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!response.data.attributes[key]) {
          response.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('receivable-invoice', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async updateImportedInvoiceAmount(updatedAmounts) {
    try {
      let payload = this.serialize({ includeId: true });

      Object.keys(updatedAmounts).forEach(key => {
        payload.data.attributes[key] = {
          ...payload.data.attributes[key],
          value: updatedAmounts[key],
        };
      });

      await apiAction(this, {
        method: 'PUT',
        data: payload,
        adapterOptions: {
          useV4Endpoint: true,
        },
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!payload.data.attributes[key]) {
          payload.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('receivable-invoice', {
        data: payload.data,
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async updateImportedInvoice() {
    try {
      let payload = this.serialize({ includeId: true });

      await apiAction(this, {
        method: 'PUT',
        data: payload,
        adapterOptions: {
          useV4Endpoint: true,
        },
      });

      // as BE is not sending back attributes with null value,
      // these attributes do not get updated and in FE they keep the old value (until refreshing the page)
      // to avoid this situation, they will be pushed in the response with the correct null value
      for (let key of Object.keys(payload.data.attributes)) {
        if (!payload.data.attributes[key]) {
          payload.data.attributes[key] = null;
        }
      }

      this.store.pushPayload('receivable-invoice', {
        data: payload.data,
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async finalizeDraft() {
    try {
      let response = await apiAction(this, {
        method: 'POST',
        path: 'finalize',
        data: this.serialize({ includeId: true }),
      });
      this.store.pushPayload('receivable-invoice', response);
      // We need to unload the record in order to force getting the correct new number that is determined by BE
      next(() => this.unloadRecord());
    } catch (error) {
      this._handleError(error);
    }
  }

  clearItemsWithNoId() {
    this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
  }

  @waitFor
  async markAsPaid(paidAt) {
    let useV4Endpoint = variation('feature--boolean-import-ar');

    try {
      let data = {
        data: {
          type: 'receivable_invoices',
          attributes: { paid_at: paidAt },
        },
      };
      let response = await apiAction(this, {
        method: 'POST',
        path: 'mark_as_paid',
        data,
        adapterOptions: {
          useV4Endpoint,
        },
      });
      this.store.pushPayload('receivable-invoice', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAsUnpaid() {
    let useV4Endpoint = variation('feature--boolean-import-ar');

    try {
      this.rollbackAttributes();
      let response = await apiAction(this, {
        method: 'POST',
        path: 'unmark_as_paid',
        adapterOptions: {
          useV4Endpoint,
        },
      });
      this.store.pushPayload('receivable-invoice', response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  static async getStats(store) {
    try {
      return await store.adapterFor('receivable-invoice').getStats();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        let sentry = getOwner(store).lookup('service:sentry');
        sentry.captureException(error);
      }
      return {
        created: {
          canceled: 0,
          draft: 0,
          paid: 0,
          total: 0,
          unpaid: 0,
        },
      };
    }
  }

  @waitFor
  static async last(store) {
    let response = await store.adapterFor('receivable-invoice').getLast();
    return store.push(response);
  }

  _handleError(error) {
    if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
      let errors = this._errorsArrayToHash(error.errors);
      this.networkManager.errorModelInjector(this, errors);
    }

    if (error.isAdapterError || error instanceof NetworkManagerError) {
      this._assignRelationshipErrors(error);
    }

    throw error;
  }

  _assignRelationshipErrors(error) {
    // reduce
    // [
    //   { attribute: "items/0/title", message: "required" }
    //   { attribute: "items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: { "title": ["required"] }
    //   1: { "title": ["required"] }
    // }
    // then assign to items
    let parsedErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, attribute] = error.attribute.split('/');
      if (invoiceAttr === 'items') {
        error = { attribute, message: error.message };
        errorsForItems[index] = errorsForItems[index] || {};
        errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
        errorsForItems[index][attribute].push(error.message);
      }
      return errorsForItems;
    }, {});

    let parsedRelationshipErrors = this.errors.reduce(
      (errorsForRelationships, relationshipError) => {
        let [invoiceAttr, attribute] = relationshipError.attribute.split('/');
        if (
          invoiceAttr === 'payment' ||
          invoiceAttr === 'welfareFund' ||
          invoiceAttr === 'withholdingTax'
        ) {
          errorsForRelationships[invoiceAttr] = errorsForRelationships[invoiceAttr] || {};
          errorsForRelationships[invoiceAttr][attribute] =
            errorsForRelationships[invoiceAttr][attribute] || [];
          errorsForRelationships[invoiceAttr][attribute].push(relationshipError.message);
        }
        return errorsForRelationships;
      },
      {}
    );

    Object.entries(parsedErrors).forEach(([index, parsedErrorsForItem]) => {
      this.networkManager.errorModelInjector(this.items[index], parsedErrorsForItem, error);
    });

    Object.entries(parsedRelationshipErrors).forEach(([attribute, parsedRelationshipError]) => {
      if (attribute === 'payment') {
        this.networkManager.errorModelInjector(this.payment, parsedRelationshipError, error);
      }
      if (attribute === 'welfareFund') {
        this.networkManager.errorModelInjector(this.welfareFund, parsedRelationshipError, error);
      }
      if (attribute === 'withholdingTax') {
        this.networkManager.errorModelInjector(this.withholdingTax, parsedRelationshipError, error);
      }
    });
  }

  // this method comes from
  // https://github.com/emberjs/data/blob/f4bfbaa148b67104d0022fd176faa0f4a612289a/packages/store/addon/-private/system/errors-utils.js#L122
  // override to support nested errors
  _errorsArrayToHash(errors) {
    let out = {};
    if (errors) {
      errors.forEach(error => {
        if (error.source && error.source.pointer) {
          let key = error.source.pointer.match(SOURCE_POINTER_REGEXP);

          if (key) {
            key = key[2];
          } else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
            key = PRIMARY_ATTRIBUTE_KEY;
          }

          if (key) {
            out[key] = out[key] || [];
            // only update: use the code as the message if it exists
            out[key].push(error.code || error.details || error.title);
          }
        }
      });
    }
    return out;
  }

  get totalAmount() {
    let totalAmount =
      parseFloat(this.totalVat) +
      parseFloat(this.totalExcludingVat) +
      parseFloat(this.welfareFundAmount) -
      parseFloat(this.withholdingTaxAmount);
    return totalAmount.toFixed(2);
  }

  get totalVatWithoutWelfareFund() {
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.totalVat);
    }, 0);
  }

  get totalVat() {
    return (
      parseFloat(this.totalVatWithoutWelfareFund) + parseFloat(this.welfareFundVatAmount)
    ).toFixed(2);
  }

  get welfareFundVatAmount() {
    return this.welfareFund?.rate
      ? (parseFloat(this.totalVatWithoutWelfareFund) * parseFloat(this.welfareFund.rate)).toFixed(2)
      : '0.00';
  }

  get totalExcludingVat() {
    return this.items
      .reduce((total, item) => {
        return parseFloat(total) + parseFloat(item.discountedTotalExcludingVat);
      }, 0)
      .toFixed(2);
  }

  get displayedStatus() {
    let statusesMap = {
      canceled: this.intl.t('receivable-invoices.status.canceled'),
      paid: this.intl.t('receivable-invoices.status.paid'),
      unpaid: this.intl.t('receivable-invoices.status.unpaid'),
      draft: this.intl.t('receivable-invoices.status.draft'),
    };
    return statusesMap[this.status];
  }

  get welfareFundAmount() {
    return this.welfareFund?.rate
      ? (parseFloat(this.totalExcludingVat) * parseFloat(this.welfareFund.rate)).toFixed(2)
      : '0.00';
  }

  get withholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return (
        (parseFloat(this.totalExcludingVat) + parseFloat(this.welfareFundAmount)) *
        parseFloat(this.withholdingTax.rate)
      ).toFixed(2);

    return (parseFloat(this.totalExcludingVat) * parseFloat(this.withholdingTax.rate)).toFixed(2);
  }

  get pdfUrl() {
    return this.store.adapterFor('receivable-invoice').urlForPdf(this.id);
  }

  @waitFor
  async setPdfPreviewIframeUrl() {
    if (!this.pdfUrl) return;

    let reader = new FileReader();
    let handler = () => {
      this.pdfPreviewIframeUrl = reader.result;
    };

    try {
      let response = await this.networkManager.rawRequest(this.pdfUrl, { method: 'GET' });
      let blob = await response.blob();

      this.fileType = blob.type;

      reader.addEventListener('load', handler);
      reader.readAsDataURL(blob);
    } catch {
      this.pdfPreviewIframeUrl = null;
      this.fileType = null;
      reader.removeEventListener(handler);
    }
  }
}
