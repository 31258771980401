import Model, { attr, belongsTo } from '@ember-data/model';
import { getOwner } from '@ember/owner';

import { CLIENT_KINDS } from 'qonto/constants/clients';
import COUNTRIES_TO_LOCALES_ARRAY, {
  DEFAULT_LOCALE_CODE,
} from 'qonto/constants/countries-to-locales';
import CURRENCIES from 'qonto/constants/currencies';
import LOCALES_ARRAY from 'qonto/constants/locales';
import ClientHubValidations from 'qonto/validations/client-hub';

/**
 * @typedef CustomerAddressHash
 * @type {object}
 * @property {string} streetAddress
 * @property {string} zipCode
 * @property {string} city
 * @property {string} provinceCode - only when country is Italy
 * @property {string} countryCode
 */

export function getDefaultClientValues(ownerOrOwned) {
  let owner = getOwner(ownerOrOwned) || ownerOrOwned;
  let organizationManager = owner.lookup('service:organization-manager');
  let organization = organizationManager.organization;
  let countryCode = organization.legalCountry;
  return {
    billingAddress: {
      countryCode,
    },
    locale: getDefaultLocale(countryCode),
    kind: CLIENT_KINDS.COMPANY,
    currency: CURRENCIES.default,
    organization,
  };
}

export function getDefaultLocale(countryCode) {
  let clientCountryCode = countryCode?.toLowerCase();

  if (!clientCountryCode) {
    return DEFAULT_LOCALE_CODE;
  }

  let clientLocaleCode = COUNTRIES_TO_LOCALES_ARRAY.find(
    locale => locale.country_code.toLowerCase() === clientCountryCode
  )?.code;

  return clientLocaleCode || DEFAULT_LOCALE_CODE;
}

export default class ClientHubModel extends Model.extend(ClientHubValidations) {
  @attr('string', { defaultValue: CLIENT_KINDS.COMPANY }) kind;
  @attr('string') name; // when kind is company
  @attr('string') firstName; // when kind is individual or freelancer
  @attr('string') lastName; // when kind is individual or freelancer
  @attr('string') email;
  /** @type {CustomerAddressHash} */
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  billingAddress;
  /** @type {CustomerAddressHash} */
  @attr('hash') deliveryAddress;
  @attr('string') locale;
  @attr('string') tinNumber;
  @attr('string') vatNumber;
  @attr('string') recipientCode;
  @attr('string') updatedAt;
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  @attr('boolean') hasReminders;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  get localeObject() {
    return LOCALES_ARRAY.find(({ code }) => code === this.locale?.toLowerCase());
  }

  set localeObject({ code }) {
    this.locale = code;
  }

  hasClientValuesChanged() {
    let clientChangedAttributes = this.changedAttributes();
    // eslint-disable-next-line no-unused-vars
    let { organization, ...defaultValues } = getDefaultClientValues(this);

    //Since we created an empty user record without saving, we can't use hasDirtyRecords
    //to check the case of creation
    //We initialized the client with getDefaultClientValues.
    //We have to check if there are more attributes added or if these have been changed
    return (
      Object.keys(clientChangedAttributes).length > Object.keys(defaultValues).length ||
      clientChangedAttributes.kind[1] !== defaultValues.kind ||
      (clientChangedAttributes.currency &&
        clientChangedAttributes.currency[1] !== defaultValues.currency) ||
      (clientChangedAttributes.billingAddress &&
        clientChangedAttributes.billingAddress[1].countryCode !==
          defaultValues.billingAddress.countryCode) ||
      clientChangedAttributes.locale[1] !== defaultValues.locale
    );
  }
}
