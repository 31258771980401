import Model, { attr } from '@ember-data/model';

import ReceivableInvoicesSettingsValidations from 'qonto/validations/receivable-invoices-settings';

export default class ReceivableInvoicesSettingsModel extends Model.extend(
  ReceivableInvoicesSettingsValidations
) {
  //Attributes that will be deprecated with the release of quotes feature
  /** @type {string} */
  @attr numberingPattern;
  /** @type {string} */
  @attr nextNumber;
  /** @type {string} */
  @attr nextInvoiceNumber;
  /** @type {string} */
  @attr nextCreditNoteNumber;

  //Attributes that will be used for new settings v1 schema
  /** @type {'automatic'|'manual'} */
  @attr numberingMode;
  /** @type {string} */
  @attr contactEmail;
  /** @type {'goods' | 'services' | 'goods_and_services' | null} */
  @attr transactionType;
  /** @type {'on_receipts' | 'compensated_for_sales' | 'exempt' | null} */
  @attr vatPaymentCondition;
  /** @type {string | null} */
  @attr discountConditions;
  /** @type {string | null} */
  @attr latePaymentPenalties;
  /** @type {string | null} */
  @attr legalFixedCompensation;
  /** @type {string} */
  @attr creditNoteNextNumber;
  /** @type {string} */
  @attr invoiceNextNumber;
  /** @type {string} */
  @attr quoteNextNumber;
  /** @type {string} */
  @attr invoiceNumberingPattern;
  /** @type {string} */
  @attr creditNoteNumberingPattern;
  /** @type {string} */
  @attr quoteNumberingPattern;
  /** @type {string} */
  @attr invoiceNextNumberFormatted;
  /** @type {string} */
  @attr quoteNextNumberFormatted;
  /** @type {string} */
  @attr creditNoteNextNumberFormatted;
  /** @type {string} */
  @attr selfInvoiceNextNumberFormatted;
  /** @type {string | null} */
  @attr invoiceHeader;
  /** @type {string | null} */
  @attr quoteHeader;
  /** @type {string | null} */
  @attr invoiceFooter;
  /** @type {string | null} */
  @attr quoteFooter;
  /** @type {string | null} */
  @attr vatNumber;
  /** @type {string | null} */
  @attr taxNumber;
  /** @type {string | null} */
  @attr companyLeadership;
  /** @type {string | null} */
  @attr districtCourt;
  /** @type {string | null} */
  // Note: this field corresponds to the RSC Number for FR orga, and to the commercial register number for DE orga
  @attr commercialRegisterNumber;
  /** @type {string | null} */
  @attr legalCapitalShare;
}
