import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';
import { equal, mapBy, reads } from 'macro-decorators';

import Validations from 'qonto/validations/mandate';

export default class MandateModel extends Model.extend(Validations) {
  @attr('string') umr; // reference
  @attr('string') status;
  @attr('boolean') recurrent;
  @attr('date') createdAt;
  @attr('string') creditorName;
  @attr('string') creditorIdentifier;
  @attr('string') activityTag;
  @attr('string') mandateType;
  @attr('date') lastDirectDebitDate;
  @attr('number') successfulDirectDebitsCount;
  @attr() directDebitsAverageAmount;
  @attr() directDebitsTotalAmount;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  enrichmentData;

  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: true, inverse: null }) organization;
  @hasMany('attachment', { async: true, inverse: null }) attachments;
  // accessing `ManyArray` using `content` property
  @reads('attachments.content.0') attachment;
  @reads('savedAttachments.length') attachmentCount;

  @equal('mandateType', 'b2b') isB2B;

  get savedAttachments() {
    return this.attachments.filter(item => get(item, 'isNew') === false);
  }

  get nonEmptyAttachments() {
    return this.attachments.filter(item => get(item, 'file'));
  }
  @mapBy('nonEmptyAttachments', 'file') attachmentsFiles;

  get isActive() {
    return this.status === 'active' && this.get('bankAccount.isActive');
  }

  get isSuspended() {
    return (
      this.status === 'suspended' || (this.status === 'active' && this.get('bankAccount.isClosed'))
    );
  }

  get avatarInfo() {
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    return {
      mediumLogo: this.enrichmentData?.logo?.medium ?? activityTagSVG,
      smallLogo: this.enrichmentData?.logo?.small ?? activityTagSVG,
      icon: this.isSuspended ? 'status_paused' : null,
    };
  }

  @tracked code = null;

  @waitFor
  async suspend() {
    let response = await apiAction(this, { method: 'PUT', path: 'suspend' });
    this.store.pushPayload(response);
  }

  @waitFor
  async unsuspend() {
    let response = await apiAction(this, { method: 'PUT', path: 'unsuspend' });
    this.store.pushPayload(response);
  }

  @waitFor
  async updateActivityTag(activityTag) {
    let data = { mandate: { activity_tag: activityTag } };
    let response = await apiAction(this, { method: 'PUT', path: 'activity', data });
    this.store.pushPayload(response);
  }
}
