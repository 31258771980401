import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';

import titleize from 'qonto/utils/titleize';
import StakeholderValidations from 'qonto/validations/stakeholder';

export default class StakeholderModel extends Model.extend(StakeholderValidations) {
  @service intl;
  @service userManager;

  @attr('string') birthCity;
  @attr('string') birthCountry;
  @attr('string') birthZipcode;
  @attr birthdate;

  @attr('number') capitalAmount;
  @attr('string') email;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') nationality;
  @attr('boolean', { defaultValue: false }) shareholder;
  @attr('date') createdAt;

  // Deprecated attritures, we keep them for backward compatibility
  // purpose, until "legal-entity" model is correctly handled.
  // It was a hack to handle legal shareholders
  @attr('string') name;
  @attr('boolean', { defaultValue: true }) physicalPerson;

  @belongsTo('address', { async: true, inverse: null }) address;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('organization', { async: false, inverse: 'stakeholders' }) organization;
  @belongsTo('user', { async: false, inverse: null }) user;

  @hasMany('document', { async: false, inverse: 'stakeholder' }) documents;

  @dependentKeyCompat
  get proofOfIdentity() {
    return this.documents.find(({ docType }) => docType === 'poi');
  }

  @dependentKeyCompat
  get proofOfIdentitySubtype() {
    return this.proofOfIdentity?.docSubtype;
  }

  get initials() {
    let { firstName = '', lastName = '' } = this;
    let initials = `${firstName.trim().charAt(0)}${lastName.trim().charAt(0)}`;

    return initials.toUpperCase();
  }

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();

    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    } else if (firstName) {
      return titleize(firstName);
    } else if (lastName) {
      return titleize(lastName);
    } else {
      return '';
    }
  }

  get displayName() {
    if (this.physicalPerson) {
      return this.fullName;
    } else if (this.fullName) {
      return this.intl.t('labels.shareholder_legal_entity', {
        name: this.name,
        fullName: this.fullName,
      });
    }

    return this.name;
  }

  get isCurrentUser() {
    return this.user?.id === this.userManager.currentUser?.id;
  }
}
