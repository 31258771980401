import Model, { attr, belongsTo } from '@ember-data/model';
import { getOwner } from '@ember/owner';

import { CLIENT_KINDS } from 'qonto/constants/clients';
import COUNTRIES_TO_LOCALES_ARRAY, {
  DEFAULT_LOCALE_CODE,
} from 'qonto/constants/countries-to-locales';
import CURRENCIES from 'qonto/constants/currencies';
import LOCALES_ARRAY from 'qonto/constants/locales';
import ClientValidations from 'qonto/validations/client';

/**
 * @typedef CustomerAddressHash
 * @type {object}
 * @property {string} streetAddress
 * @property {string} zipCode
 * @property {string} city
 * @property {string} provinceCode - only when country is Italy
 * @property {string} countryCode
 */

export function getDefaultCustomerValues(ownerOrOwned) {
  let owner = getOwner(ownerOrOwned) || ownerOrOwned;
  let organizationManager = owner.lookup('service:organization-manager');
  let organization = organizationManager.organization;
  let countryCode = organization.legalCountry;
  return {
    countryCode,
    billingAddress: {
      countryCode,
    },
    locale: getDefaultLocale(countryCode),
    kind: CLIENT_KINDS.COMPANY,
    currency: CURRENCIES.default,
    organization,
  };
}

export function getDefaultLocale(countryCode) {
  let customerCountryCode = countryCode?.toLowerCase();

  if (!customerCountryCode) {
    return DEFAULT_LOCALE_CODE;
  }

  let customerLocaleCode = COUNTRIES_TO_LOCALES_ARRAY.find(
    ({ country_code }) => country_code === customerCountryCode
  )?.code;

  return customerLocaleCode || DEFAULT_LOCALE_CODE;
}

export default class CustomerModel extends Model.extend(ClientValidations) {
  @attr('string', { defaultValue: CLIENT_KINDS.COMPANY }) kind;
  @attr('string') name; // when kind is company
  @attr('string') firstName; // when kind is individual or freelancer
  @attr('string') lastName; // when kind is individual or freelancer
  @attr('string') email;
  /** @deprecated use billingAddress instead */
  @attr('string') address;
  /** @deprecated use billingAddress instead */
  @attr('string') zipCode;
  /** @deprecated use billingAddress instead */
  @attr('string') city;
  /** @deprecated use billingAddress instead */
  @attr('string') provinceCode; // only when country is Italy
  /** @deprecated  use billingAddress instead */
  @attr('string') countryCode;
  /** @type {CustomerAddressHash} */
  @attr('hash') billingAddress;
  /** @type {CustomerAddressHash} */
  @attr('hash') deliveryAddress;
  @attr('string') locale;
  @attr('string') tinNumber;
  @attr('string') vatNumber;
  @attr('string') recipientCode;
  @attr('string') updatedAt;
  @attr('string', { defaultValue: CURRENCIES.default }) currency;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  get localeObject() {
    return LOCALES_ARRAY.find(({ code }) => code === this.locale?.toLowerCase());
  }

  set localeObject({ code }) {
    this.locale = code;
  }

  get isCompany() {
    return this.kind === CLIENT_KINDS.COMPANY;
  }

  get isFreelancer() {
    return this.kind === CLIENT_KINDS.FREELANCER;
  }

  get isIndividual() {
    return this.kind === CLIENT_KINDS.INDIVIDUAL;
  }
}
