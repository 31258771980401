import Model, { attr, hasMany } from '@ember-data/model';

export default class SubscriptionProduct extends Model {
  @attr('string') productType;
  @attr('string') code;
  @attr('string') brandName;
  @attr('string') lineup;
  @attr('string') description;
  @attr('boolean') descriptionHasDecoration;
  @attr('string') subDescription;
  @attr('hash') monthlyPrice;
  @attr('hash') annualPrice;
  @attr('hash') benefitGroups;
  @attr('hash') featureGroups;

  @hasMany('subscription-product', { async: false, inverse: null }) addons;

  @attr('boolean') isAvailable;
  @attr('string') iconLightUrl;
  @attr('string') iconDarkUrl;
  @attr('hash') traits;

  get isAddon() {
    return this.productType === 'addon';
  }
}
