import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import CURRENCIES from 'qonto/constants/currencies';
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';

export default class SupplierModel extends Model {
  @attr('string') name;
  @attr('string') email;
  @attr('string') phoneNumber;
  @attr('number') vatNumber;
  @attr('number') defaultVatRate;
  @attr('string') tinNumber;
  @attr('string') recipientCode;
  @attr('string') streetAddress;
  @attr('string') zipCode;
  @attr('string') provinceCode;
  @attr('string') city;
  @attr('string') countryCode;
  @attr('string', { defaultValue: '0.00' }) outstandingBalance;
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  @attr('string') iban;
  @attr('string', { defaultValue: SUPPLIER_STATUSES.UNARCHIVED }) status;

  @belongsTo('organization', { async: false, inverse: null }) organization;
  @hasMany('beneficiary', { async: false, inverse: null }) beneficiary;
}
