import Model, { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

export default class SupplierInvoiceModel extends Model {
  @attr invoiceNumber;
  @attr fileName;
  @attr supplierName;
  @attr description;
  @attr totalAmount;
  @attr status;
  @attr iban;
  @attr source; // 'email_forward' | 'pay_by_invoice' | 'e_invoicing' | 'supplier_invoices'
  @attr selfInvoiceId;
  @attr requestTransfer;
  @attr hasDuplicates;
  @attr isAttachmentInvoice;
  @attr isAttachmentNonFinancial;
  /** @type {string} YYYY-MM-DD */
  @attr dueDate;
  /** @type {string} YYYY-MM-DD */
  @attr paymentDate;
  /** @type {string} YYYY-MM-DD */
  @attr scheduledDate;
  /** @type {string} ISO Date String  */
  @attr createdAt;
  /** @type {string} ISO Date String  */
  @attr analyzedAt; // Ex value: "2022-03-04T17:58:30+02:00"

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  @belongsTo('attachment', { async: false, inverse: null }) attachment;

  beneficiaryId;

  @waitFor
  async markAsPaid(paymentDate) {
    let data = { supplier_invoice: { payment_date: paymentDate } };
    let response = await apiAction(this, { method: 'POST', path: 'mark_as_paid', data });
    this.store.pushPayload(response);
  }
}
