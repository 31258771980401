import { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import { equal, not, or } from 'macro-decorators';

import {
  CARD_DESIGNS,
  CARD_LEVELS,
  CARD_NAMES,
  CARD_STATUSES,
  CARD_STATUSES_COLORS,
  CARD_STATUSES_INSURED,
  CARD_TYPES,
  CARD_VALID_DESIGNS,
  X_CARD_VALID_DESIGNS,
} from 'qonto/constants/cards';
import Subject from 'qonto/models/subject';
import CardValidations from 'qonto/validations/card';

export default class CardModel extends Subject.extend(CardValidations) {
  idempotencyKey = null;

  @service featuresManager;
  @service intl;
  @service networkManager;
  @service organizationManager;

  @attr cardLevel;
  @attr nickname;
  @attr('string') slug;
  @attr('string') cardDesign;
  @attr('string') typeOfPrint;
  @attr('boolean', { defaultValue: null }) shipToBusiness;
  @attr('boolean', { defaultValue: false }) atmOption;
  @attr('boolean', { defaultValue: false }) nfcOption;
  @attr('boolean', { defaultValue: true }) foreignOption;
  @attr('boolean', { defaultValue: true }) onlineOption;
  @attr('boolean', { defaultValue: false }) pinSet;
  @attr('boolean', { defaultValue: false }) hadOperation;
  @attr('boolean', { defaultValue: false }) hadPinOperation;
  @attr('boolean', { defaultValue: false }) eligibleForRenewal;
  @attr('boolean', { defaultValue: false }) eligibleForUpsell;
  @attr('boolean', { defaultValue: false }) renewal;
  @attr('boolean', { defaultValue: false }) renewed;
  @attr('boolean', { defaultValue: false }) upsell;
  @attr('boolean', { defaultValue: false }) upsold;
  @attr(undefined, { defaultValue: () => [0, 1, 2, 3, 4, 5, 6] }) activeDays;
  @attr(undefined, { defaultValue: () => null }) categoryTags;
  @attr('number') atmDailyLimit;
  @attr('number') atmDailySpent;
  @attr('boolean', { defaultValue: false }) atmDailyLimitOption;
  @attr('number') atmMonthlyLimit;
  @attr('number') atmMonthlySpent;
  @attr parentCardSummary;
  @attr('number') paymentDailyLimit;
  @attr('number') paymentDailySpent;
  @attr('boolean', { defaultValue: false }) paymentDailyLimitOption;
  @attr('number') paymentMonthlyLimit;
  @attr('number') paymentMonthlySpent;
  @attr('number') paymentTransactionLimit;
  @attr('boolean', { defaultValue: false }) paymentTransactionLimitOption;
  @attr('number') paymentLifespanLimit;
  @attr('number') paymentLifespanSpent;
  @attr('string') discardOn;
  @attr('string') pin;
  @attr('string') pinConfirmation;
  @attr('string') expDate;
  @attr('string') last4;
  @attr('string') maskPan;
  @attr('string') embossedName;
  @attr('string', { defaultValue: CARD_STATUSES.PENDING }) status;
  @attr('date') preExpiresAt;
  @attr('date') lastActivityAt;
  @attr('date') forwardedAt;
  @attr('date') shippedAt;
  @attr('date') createdAt;
  @attr('boolean', { defaultValue: false }) isQcp;

  @belongsTo('membership', { async: true, inverse: null }) holder;
  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  @belongsTo('address', { async: false, inverse: null }) address;

  //Attr set for front purposes
  @attr('string') reason;

  // order_canceled cards are not sent by BE, however when cancelling a card it still appears a while in the list until it's refreshed
  @or('deleted', 'stolen', 'lost', 'preExpired', 'expired', 'orderCanceled') ghost;

  @or('live', 'paused', 'pinBlocked') isActive;

  @or('live', 'pinBlocked') isOperational;

  @not('pinSet') hasNoPin;

  // card level
  @equal('cardLevel', CARD_LEVELS.ADVERTISING) isAdvertising;
  @equal('cardLevel', CARD_LEVELS.FLASH) isFlash;
  @equal('cardLevel', CARD_LEVELS.METAL) isMetal;
  @equal('cardLevel', CARD_LEVELS.PLUS) isPlus;
  @equal('cardLevel', CARD_LEVELS.VIRTUAL) isVirtual;

  // card statuses
  @equal('status', CARD_STATUSES.PENDING) pending;
  @equal('status', CARD_STATUSES.ORDER_CANCELED) orderCanceled;
  @equal('status', CARD_STATUSES.LIVE) live;
  @equal('status', CARD_STATUSES.PAUSED) paused;
  @equal('status', CARD_STATUSES.STOLEN) stolen;
  @equal('status', CARD_STATUSES.LOST) lost;
  @equal('status', CARD_STATUSES.PIN_BLOCKED) pinBlocked;
  @equal('status', CARD_STATUSES.DELETED) deleted;
  @equal('status', CARD_STATUSES.PRE_EXPIRED) preExpired;
  @equal('status', CARD_STATUSES.EXPIRED) expired;

  get design() {
    let validDesigns = [...CARD_VALID_DESIGNS, ...X_CARD_VALID_DESIGNS];
    return this.cardDesign && validDesigns.includes(this.cardDesign)
      ? this.cardDesign
      : this.cardLevel;
  }

  get isToPinActivate() {
    return this.isPhysical && this.live && !this.hadPinOperation;
  }

  get displayedStatus() {
    switch (this.status) {
      case CARD_STATUSES.DELETED:
      case CARD_STATUSES.ORDER_CANCELED:
        return this.intl.t('cards.status.deleted');
      case CARD_STATUSES.EXPIRED:
      case CARD_STATUSES.PRE_EXPIRED:
        return this.intl.t('cards.status.expired');
      case CARD_STATUSES.LIVE:
        return this.isToPinActivate
          ? this.intl.t('cards.status.to-pin-activate')
          : this.intl.t('cards.status.live');
      case CARD_STATUSES.LOST:
        return this.intl.t('cards.status.lost');
      case CARD_STATUSES.PAUSED:
        return this.intl.t('cards.status.paused');
      case CARD_STATUSES.PENDING:
        return this.holder.get('kycPending')
          ? this.intl.t('cards.status.kyc.pending')
          : this.intl.t('cards.status.pending');
      case CARD_STATUSES.PIN_BLOCKED:
        return this.intl.t('cards.status.pin_blocked');
      case CARD_STATUSES.STOLEN:
        return this.intl.t('cards.status.stolen');
      default:
        return null;
    }
  }

  get statusColor() {
    return CARD_STATUSES_COLORS[this.status];
  }

  get displayedName() {
    if (this.isPhysical) {
      if (this.cardDesign === CARD_DESIGNS.PLUS_BLACK_2023) {
        return this.intl.t('cards.fullname.plus-black-2023');
      }

      let name = CARD_NAMES[this.cardLevel];
      return this.intl.t('cards.fullname.physical', { cardName: name });
    }

    switch (this.cardLevel) {
      case CARD_LEVELS.VIRTUAL:
        return this.intl.t('cards.fullname.virtual');
      case CARD_LEVELS.FLASH:
        return this.intl.t('cards.fullname.flash');
      case CARD_LEVELS.ADVERTISING:
        return this.intl.t('cards.fullname.advertising');
    }

    return null;
  }

  get isPhysical() {
    return CARD_TYPES.PHYSICALS.includes(this.cardLevel);
  }

  get isExpiring() {
    return this.eligibleForRenewal && this.isActive;
  }

  get isRenewable() {
    return this.isPhysical && this.isExpiring && !this.renewed;
  }

  get isRenewed() {
    return this.renewed && this.isActive;
  }

  get isReplacement() {
    return this.cardLevel === CARD_LEVELS.VIRTUAL
      ? this.renewal && !this.hadOperation
      : this.renewal && this.pending;
  }

  get isUpsold() {
    return (
      this.upsold &&
      !this.renewed &&
      [CARD_LEVELS.STANDARD, CARD_LEVELS.PLUS].includes(this.cardLevel) &&
      this.isActive
    );
  }

  get spacedMaskPan() {
    return this.maskPan?.match(/.{4}/g)?.join(' ');
  }

  get displayedMaskPan() {
    // when provided, the maskPan is without spaces
    let maskChar = '•';
    return this.maskPan ? this.maskPan.replace(/\D/g, maskChar) : maskChar.repeat(16);
  }

  get hasCategoryTags() {
    return Boolean(this.categoryTags?.length);
  }

  get activeDaysOption() {
    return this.activeDays.length < 7;
  }

  get isPinSettable() {
    return this.hasNoPin && this.isPhysical && this.pending;
  }

  get isPinResetable() {
    return this.isPhysical && this.pinSet && this.isOperational && this.hadPinOperation;
  }

  get isInsuranceCovered() {
    return this.isPhysical && CARD_STATUSES_INSURED.includes(this.status);
  }

  get isReportableAsLostOrStolen() {
    return this.isPhysical && this.isOperational;
  }

  setPin(shouldReset = false) {
    return shouldReset ? this.updatePinAction() : this.setPinAction();
  }

  addIdempotencyKey() {
    this.idempotencyKey = crypto.randomUUID();
  }

  removeIdempotencyKey() {
    this.idempotencyKey = null;
  }

  addIdempotencyHeader() {
    this.networkManager.addIdempotencyHeader(this.idempotencyKey);
  }

  removeIdempotencyHeader() {
    this.removeIdempotencyKey();
    this.networkManager.removeIdempotencyHeader();
  }

  resetPinProperties() {
    // don't keep the PIN in the UI
    this.setProperties({
      pin: null,
      pinConfirmation: null,
      pinSet: true,
    });
  }

  displayedCardName(customCardLevel) {
    switch (customCardLevel ?? this.cardLevel) {
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.fullname.physical', {
          cardName: this.intl.t('cards.name.metal'),
        });
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.fullname.physical', {
          cardName: this.intl.t('cards.name.plus'),
        });
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.fullname.physical', {
          cardName: this.intl.t('cards.name.standard'),
        });
      case CARD_LEVELS.VIRTUAL:
        return this.intl.t('cards.name.virtual');
      case CARD_LEVELS.FLASH:
        return this.intl.t('cards.name.flash');
      case CARD_LEVELS.ADVERTISING:
        return this.intl.t('cards.name.advertising');
      default:
        return null;
    }
  }

  @waitFor
  async changeLimits() {
    let {
      atm_daily_limit,
      atm_daily_limit_option,
      atm_monthly_limit,
      payment_daily_limit,
      payment_daily_limit_option,
      payment_monthly_limit,
      payment_transaction_limit,
      payment_transaction_limit_option,
    } = this.serialize();

    let data = {
      card: {
        atm_daily_limit,
        atm_daily_limit_option,
        atm_monthly_limit,
        payment_daily_limit,
        payment_daily_limit_option,
        payment_monthly_limit,
        payment_transaction_limit,
        payment_transaction_limit_option,
      },
    };

    let response = await apiAction(this, { method: 'PATCH', path: 'limits', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async changeLifespanLimits() {
    let { payment_lifespan_limit } = this.serialize();
    let data = { card: { payment_lifespan_limit } };

    let response = await apiAction(this, { method: 'PATCH', path: 'limits', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async changeRestrictions() {
    let { active_days, category_tags } = this.serialize();
    let data = { card: { active_days } };

    if (category_tags) {
      data.card.category_tags = category_tags;
    }

    let response = await apiAction(this, { method: 'PATCH', path: 'restrictions', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async changeOptions() {
    let { atm_option, nfc_option, foreign_option, online_option } = this.serialize();

    let data = { card: { atm_option, nfc_option, foreign_option, online_option } };

    let response = await apiAction(this, { method: 'PATCH', path: 'options', data });

    let serializer = this.store.serializerFor('card');
    let normalizedRecord = serializer.normalizeSingleResponse(
      this.store,
      this.constructor,
      response,
      `${response?.card?.id || '(unknown)'}`,
      'findRecord'
    );

    return this.store.push(normalizedRecord);
  }

  @waitFor
  async delete() {
    let response = await apiAction(this, { method: 'DELETE' });
    this.store.pushPayload(response);
  }

  @waitFor
  async getNumbers() {
    return await apiAction(this, { method: 'GET', path: 'numbers' });
  }

  @waitFor
  async lock(reason) {
    let data = { card: { reason } };
    let response = await apiAction(this, { method: 'PUT', path: 'lock', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async getPin() {
    return await apiAction(this, { method: 'GET', path: 'pin' });
  }

  @waitFor
  async setPinAction() {
    let { pin } = this.serialize();
    let data = { card: { pin } };

    await apiAction(this, { method: 'POST', path: 'pin', data });
    this.resetPinProperties();
  }

  @waitFor
  async unlock() {
    let response = await apiAction(this, { method: 'PUT', path: 'unlock' });
    this.store.pushPayload(response);
  }

  @waitFor
  async updatePinAction() {
    let { pin } = this.serialize();
    let data = { card: { pin } };

    await apiAction(this, { method: 'PUT', path: 'pin', data });
    this.resetPinProperties();
  }

  @waitFor
  async updateNickname(nickname) {
    let data = { card: { nickname } };

    let response = await apiAction(this, { method: 'PATCH', path: 'nickname', data });
    this.store.pushPayload(response);
  }
}
