import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import { bool } from 'macro-decorators';

import LOCALES_ARRAY from 'qonto/constants/locales';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import titleize from 'qonto/utils/titleize';
import Validations from 'qonto/validations/user';

export default class userModel extends Model.extend(Validations) {
  @service sessionManager;
  @service localeManager;
  @service networkManager;

  @attr('string') locale;
  @attr('string') gender;
  @attr birthdate;
  @attr('string') birthCity;
  @attr('string') birthCountry;

  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') email;
  @attr('string') phoneNumber;
  @attr zendesk;

  @attr flags;
  @attr('string') slug;
  @attr('string') timezone;
  @attr('string') password;
  @attr('string') currentPassword;
  @attr('string') code;
  @attr('string') pendingEmail;
  @attr('string') pendingEmailConfirmationCode;
  @attr('boolean') mfaEnabled;
  @attr('string') mfaMethod;
  @attr italianFiscalCode;
  @attr() scaEnabled;
  @attr() loginToken;

  @attr('file') avatar;
  @attr('file') avatarThumb;
  @attr('file') defaultAvatar;
  @attr('file') defaultAvatarThumb;
  @attr('boolean') hasDefaultAvatar;

  @hasMany('membership', { async: false, inverse: 'user' }) memberships;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();
    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }
  }

  get hasPassword() {
    return this.flags.has_password;
  }

  get localeObject() {
    return LOCALES_ARRAY.find(item => get(item, 'code') === this.locale);
  }

  set localeObject(val) {
    this.set('locale', val?.code);
  }

  get organizations() {
    return this.memberships.map(item => get(item, 'organization'));
  }

  get organizationsEnabled() {
    return this.organizations.filter(item => get(item, 'accessDisabled') === false);
  }

  @bool('avatar.fileUrl') hasAvatar;

  get picture() {
    let hasNoAvatar = !this.hasAvatar;
    return hasNoAvatar && this.hasDefaultAvatar ? this.defaultAvatar : this.avatar;
  }

  @waitFor
  async removeAvatar() {
    let data = { user: { avatar: null } };
    let response = await apiAction(this, { method: 'PUT', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async changePassword() {
    let { current_password, password } = this.serialize();
    let data = { user: { current_password, password } };

    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'passwords/change',
        data,
      });
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async confirmWithPassword() {
    let { current_password } = this.serialize();
    let data = { user: { current_password } };

    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'passwords/confirm',
        data,
      });
    } catch (error) {
      if (error instanceof InvalidError && error.errors) {
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }
}
